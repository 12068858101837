export const navLinks = [
  // {
  //   label: 'Projects',
  //   pathname: '/projects',
  // },
  // {
  //   label: 'About',
  //   pathname: '/about',
  // },
  // {
  //   label: 'Blog',
  //   pathname: '/blog',
  // },
  {
    label: 'Contact',
    pathname: '/contact',
  },
];

export const socialLinks = [{
    label: 'E-Mail',
    url: 'mailto:hi@ferjal0.dev',
    icon: 'send',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/ferjalo/',
    icon: 'linkedin',
  },
  {
    label: 'Github',
    url: 'https://www.github.com/ferjal0',
    icon: 'github',
  },
];