import { useTheme } from 'components/ThemeProvider';
import './index.css';

function Monogram() {
  const theme = useTheme();
  const { themeId } = theme;

  const logoBaseColor = themeId === 'light' ? '#000' : '#fff';

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 20 170 120"
      fill={logoBaseColor}
    >
      <path d="M37.5 26.7c1 1.6 4 5.8 6.6 9.5 2.7 3.7 4.9 7 4.9 7.3 0 .3-5.4.5-12.1.5-6.6 0-11.9.4-11.7.8.6 1.6 60.3 87.2 60.8 87.2.3 0 3.1-3.8 6.3-8.5 3.2-4.7 6.1-8.3 6.5-8.1.4.2 3.2 4.1 6.2 8.5s5.9 7.9 6.4 7.8c.5-.2 1.8-1.9 2.9-3.8l2.1-3.6-31.6-45.4c-17.4-25-31.4-45.7-31.1-46.2.2-.4 15.6-.7 34.1-.7h33.7l2.8-4 2.8-4H35.8l1.7 2.7zm36.1 52c9.9 14.1 18.2 26.1 18.6 26.8.7 1.1-4.8 10.5-6.1 10.5-.5 0-19.3-26.6-43-61-1.3-1.9-1.1-2 5.7-2h7l17.8 25.7z" />
      <path d="M67.6 45.2c.4.7 1.8 2.8 3.1 4.5l2.4 3.3 28.4.2 28.4.3L117 72c-16.7 23.9-15.7 21.9-12.8 26.4 1.2 1.9 2.5 3.6 2.8 3.5.5 0 39.2-55.5 39.8-57.1.2-.4-17.7-.8-39.8-.8-31.8 0-40 .3-39.4 1.2z" />
    </svg>
  );
}

export default Monogram;
